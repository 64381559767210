<template>
  <div class="profile-shipments  max-width">
    <!-- products -->
    <div :key="keyUpdated">
      <!-- filters -->
      <v-row align="center" justify="space-between">
        <v-row>
          <v-col cols="12" justify-center="center" md="3" >
            <base-select
              class="mx-3"
              :placeholder="
                $route.name == 'Profile-Products'
                  ? $t('products')
                  : $t('shipments')
              "
              :rules="[]"
              :items="types"
              v-model="filterType"
              @input="filterHandler"
            ></base-select>
          </v-col>
        </v-row>
        <!-- add new -->
        <v-col cols="12" md="3">
          <v-btn
            class="c-btn px-1"
            color="primary"
            elevation="1"
            height="37"
            block
            @click="
              filterType == 'shipments'
                ? $router.push('/add-shipment')
                : $router.push('/buy-product/offline')
            "
            ><span class="font-25 mx-1">+</span><span class="font-300 font-20 white--text">{{
              filterType == "shipments"
                ? $t("addNewShipment")
                : $t("addNewProduct")
            }}</span></v-btn
          >
        </v-col>
      </v-row>
      <!-- items -->
      <v-row v-if="!loading && data.length > 0">
        <v-col
          cols="12"
          md="4"
          class="relative"
          v-for="(item, i) in data"
          :key="i"
        >
          <product-card :item="item" :isStatus="true" :route="item.route">
            <template #bids>
              <!-- user count -->
              <div class="product-bids-icon">
                <div
                  class="user-number relative mt-1"
                  v-if="item.status == 'pending'"
                >
                  <span class="primary white--text font-22">{{
                    item.bids.length
                  }}</span>
                </div>
              </div>
            </template>

            <template name="status">
              <div
                class="text-center product-status mt-5"
                :style="{ background: statusColor(item.status) }"
              >
                <span
                  v-if="item.status == 'pending'"
                  class="white--text font-20 py-2 d-block"
                  >{{ $t("acceptingBids") }}</span
                >
                <span
                  v-else-if="item.status == 'completed'"
                  class="white--text font-20 py-2 d-block"
                  >{{ $t("completed") }}</span
                >
                <span
                  v-else-if="item.status == 'aggrement'"
                  class="white--text font-20 py-2 d-block"
                  >{{ $t("agreementReached") }}</span
                >
                <span
                  v-else-if="item.status == 'payment'"
                  class="white--text font-20 py-2 d-block"
                  >{{ $t("deliveryStage") }}</span
                >
                             
                <span
                  v-else-if="item.status == 'in progress'"
                  class="white--text font-20 py-2 d-block"
                  >{{ $t("InProgress") }}</span
                >
              </div>
            </template>
          </product-card>
        </v-col>
      </v-row>
      <!-- if empty -->
      <no-items v-if="!loading && data.length == 0" :title="$t('noShipment')"></no-items>

      <!-- if loading -->
      <div v-if="loading">
        <v-row>
          <v-col cols="12" md="4" v-for="n in 3" :key="n">
         <skeleton-card/>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- delete dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <delete-shipment
        @close="deleteDialog = false"
        @ok="okHandler"
        url="/deleteShipmentBySender"
        :id="selectedDeleteID"
      ></delete-shipment>
    </v-dialog>
  </div>
</template>

<script>
import ProductCard from "../../../components/core/ProductCard.vue";
import DeleteShipment from "../../../components/dialogs/DeleteShipment.vue";
export default {
  components: {
    DeleteShipment,
    ProductCard,
  },
  data: (vm) => ({
    deleteDialog: false,
    filterType: "shipments",
    shipments: [],
    products: [],
    data: [],
    keyUpdated: false,
    types: [
      { name: vm.$t("shipments"), id: "shipments" },
      { name: vm.$t("products"), id: "products" },
    ],
    selectedDeleteID: "",
    loading: false,
  }),
  computed: {
    userID() {
      return localStorage.getItem("userID");
    },
  },
  methods: {
    async filterHandler(value) {
      this.$router.replace(`/profile/${value}`);
      await this.getData();
    },
    statusColor(status) {
      if (status == "pending") return "#94a3b8";
      if (status == "aggrement") return "#FAB544";
      if (status == "payment") return "#66DED7";
      if (status == "in progress") return "#A864C0";
      if (status == "completed") return "#1CA933";
    },
    async reloadData() {
      await this.getData();
      this.keyUpdated = !this.keyUpdated;
    },
    deleteHandler(id) {
      this.selectedDeleteID = { key: "shipment_id", id };
      this.deleteDialog = true;
    },
    async okHandler() {
      this.deleteDialog = false;
      await this.getData();
    },
    async getData() {
      this.loading = true;
      let { data } = await this.$axios.get("/getMyShipmentsDetails");
      if (data.data) {
        if (this.$route.name == "Profile-Products") {
          this.data = [
            ...data.data.products.map((item) => {
              return {
                route: "/myProduct/details/" + item.id,
                id: item.id,
                owner_id: item.owner_id,
                title: item.title,
                image: item.image == "#" ? this.$defaultImage : item.image,
                images: [],
                toCountry: item.country_destination,
                toCity: item.city_destination,
                date: item.date,
                price: item.price,
                weight: item.weight,
                desc: item.description,
                status: item.status,
                bids: item.bids,
              };
            }),
          ];
        } else {
          this.data = [
            ...data.data.shipments.map((item) => {
              return {
                route: "/myShipment/details/" + item.id,
                id: item.id,
                title: item.title,
                image: item.image1 == "#" ? this.$defaultImage : item.image1,
                images: item.images,
                fromCity: item.city_origin,
                toCity: item.city_destination,
                date: item.date,
                budget: item.budget,
                weight: item.weight,
                status: item.status,
                bids: item.bids,
              };
            }),
          ];
        }
      }
      this.loading = false;
    },
  },
  created() {
    this.getData();
    if (this.$route.name == "Profile-Products") {
      this.filterType = "products";
    } else {
      this.filterType = "shipments";
    }
  },
};
</script>

<style lang="scss">
.profile-shipments {
  .base-select {
    fieldset {
      // background: white;
      // border: 1px solid $graybg !important;
      // border-radius: 3px;
      height: 43px !important;
    }
    i,
    input {
      // color: $purple;
    }
  }
  .product-status {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }
}
</style>
